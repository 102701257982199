import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "local-k8s-setup"
    }}>{`Local K8S Setup`}</h1>
    <p>{`This guide will help you get some CLIs set up that will help you view deployments, pods, logs, etc in our cluster.`}</p>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <p>{`You'll first want to use brew to install all the tools and dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`brew install kubernetes-cli argocd tunnelblick saml2aws aws-iam-authenticator
`}</code></pre>
    <p>{`Next, copy this into your `}<inlineCode parentName="p">{`.zshrc/.bashrc/.bash_profile`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`function k8s-context () {
    kubectl config use-context \${1}
}

function k8s-login {
    saml2aws login -a kuali-build --session-duration=28800
    export AWS_PROFILE=kuali-build
    export KUBECONFIG=~/.kube/config
}
`}</code></pre>
    <p>{`Now go into 1password and look for a secret note entitled "Kubernetes Local Files". Download the 3 files in that note.`}</p>
    <ul>
      <li parentName="ul">{`Save `}<inlineCode parentName="li">{`config`}</inlineCode>{` to `}<inlineCode parentName="li">{`~/.kube/config`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Open up `}<inlineCode parentName="li">{`setup-saml-to-aws.sh`}</inlineCode>{` and input your one login username and password. Execute the file. Now you can delete it.`}</li>
      <li parentName="ul">{`Add the `}<inlineCode parentName="li">{`kuali.opvn`}</inlineCode>{` as a configuration in tunnelblick`}</li>
    </ul>
    <h2 {...{
      "id": "daily-use"
    }}>{`Daily Use`}</h2>
    <p>{`Start tunnelblick and connect to the VPN. You'll need to use your OneLogin username/password/OTP to login. Then run the following commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`# You'll have to do this once every morning
k8s-login

# Now run this to see all the available contexts
kubectl config get-contexts

# Pick one of those and use this command to switch to it
k8s-context <context-name-here>

# now log in to argo
argocd login --core
`}</code></pre>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p>{`You're in! Here are some example commands you could run and what they do:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`# grab all the namespaces in your context
kubectl get ns

# view all the deployments in the prod namespace
kubectl -n prod get deployments

# view all the pods in the prod namespace
kubectl -n prod get pods

# view all the pods in the prod namespace for a specific deployment
kubectl -n prod get pods -l app=<deployment-name>

# view the deployment manifest for a specific deployment in the prod namespace
kubectl -n prod get deployment <deployment-name> -o yaml

# view the logs of a specific pod in the verify namespace
kubectl -n verify logs <pod-name>

# same as above but view the previously running, now dead pod
kubectl -n verify logs -p <pod-name>

# See health events (events are only stored for an hour)
kubectl -n verify get events

# see all the argo apps
argocd app list

# view details for a specific argo app
argocd app get <app-name>

# force the argo app to syncronize (this happens automatically every 30 seconds)
argocd app sync <app-name>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      